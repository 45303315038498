import styles from "./AttrezzaturaCard.module.css";

const AttrezzaturaCard = () => {
  return (
    <div className={styles.attrezzatura}>
      <div className={styles.laMiaAttrezzaturaParent}>
        <h2 className={styles.laMiaAttrezzatura}>La mia Attrezzatura</h2>
        <div>
          <img
            className={styles.frameChild}
            alt="console da dj di lorenzo goretti"
            src="/img-gallery-console@2x.png"
          />
        </div>
        <div className={styles.rectangleParent}>
          <img
            className={styles.frameItem}
            alt="cassa per eventi di lorenzo goretti"
            src="/img-gallery-cassa@2x.png"
          />
          <img
            className={styles.frameItem}
            alt="cassa per eventi di lorenzo goretti"
            src="/img-gallery-altra-cassa@2x.png"
          />
          <img
            className={styles.frameItem}
            alt="tavolo da dj di lorenzo goretti"
            src="/img-gallery-tavolodj@2x.png"
          />
          <img
            className={styles.frameItem}
            alt="stativi per casse di lorenzo goretti"
            src="/img-gallery-stativi@2x.png"
          />
          <img
            className={styles.frameItem}
            alt="luci da dj di lorenzo goretti"
            src="/img-gallery-luci@2x.png"
          />
        </div>
      </div>
    </div>
  );
};

export default AttrezzaturaCard;
