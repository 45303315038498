import { useMemo } from "react";
import styles from "./FormGalleryMatrimoni.module.css";

const FormGalleryMatrimoni = ({
  eventTypeName,
  propPadding,
  propDisplay,
  propWidth,
}) => {
  const galleryMatrimoniStyle = useMemo(() => {
    return {
      padding: propPadding,
    };
  }, [propPadding]);

  const matrimoniStyle = useMemo(() => {
    return {
      display: propDisplay,
      width: propWidth,
    };
  }, [propDisplay, propWidth]);

  return (
    <div className={styles.galleryMatrimoni} style={galleryMatrimoniStyle}>
      <div className={styles.galleryMatrimoni1}>
        <h1 className={styles.title}>Matrimoni</h1>
        <div className={styles.frameParent}>
          <img
            className={styles.frameChild}
            alt="coppia di sposi che si baciano"
            src="gallery-matrimoni-1.jpg"
          />
          <img
            className={styles.frameChild}
            alt="tavola imbandita di un matrimonio"
            src="gallery-matrimoni-2.jpg"
          />
        </div>
        <div className={styles.frameGroup}>
          <img
            className={styles.frameChild}
            alt="persone che ballano ad un matrimonio mentre un dj mette la musica"
            src="gallery-matrimoni-3.jpg"
          />
          <video className={styles.wrapper} controls>
            <source src="/777bc8a9c9544a9497cf7d88528cc0aa.mov" />
          </video>
        </div>
      </div>
    </div>
  );
};

export default FormGalleryMatrimoni;
