import { useMemo } from "react";
import Property1Variant from "./Property1Variant";
import styles from "./CardFormFilter.module.css";

const CardFormFilter = ({
  imageSize,
  eventType,
  imageDescription,
  eventImageUrl,
  propTop,
  propHeight,
  propBottom,
  propMaxHeight,
  onButton1Click,
  onButton2Click,
}) => {
  const img18esimiIconStyle = useMemo(() => {
    return {
      top: propTop,
      height: propHeight,
      bottom: propBottom,
      maxHeight: propMaxHeight,
    };
  }, [propTop, propHeight, propBottom, propMaxHeight]);

  return (
    <div className={styles.primaRiga}>
      <div className={styles.contentMatrimoni}>
        <img className={styles.imgMatrimoniIcon} alt="mani che si tengono di una coppia ad un matrimonio e sala di un evento aziendale" src={imageSize} />
        <div className={styles.content18esimi}>
          <h3 className={styles.matrimoni}>{eventType}</h3>
          <Property1Variant
            button="Scopri di più"
            property1Variant2Border="none"
            property1Variant2BackgroundColor="#cea350"
            property1Variant2Width="unset"
            buttonDisplay="inline-block"
            buttonTextDecoration="unset"
            buttonFontWeight="unset"
            onButtonClick={onButton1Click}
          />
        </div>
      </div>
      <div className={styles.contentMatrimoni}>
        <img
          className={styles.imgMatrimoniIcon}
          alt="palloncini ad un 18esimo e persone che fanno un brindisi"
          src={imageDescription}
          style={img18esimiIconStyle}
        />
        <div className={styles.content18esimi}>
          <h3 className={styles.matrimoni}>{eventImageUrl}</h3>
          <Property1Variant
            button="Scopri di più"
            property1Variant2Border="none"
            property1Variant2BackgroundColor="#cea350"
            property1Variant2Width="unset"
            buttonDisplay="inline-block"
            buttonTextDecoration="unset"
            buttonFontWeight="unset"
            onButtonClick={onButton2Click}
          />
        </div>
      </div>
    </div>
  );
};

export default CardFormFilter;
