import Property1Variant from "./Property1Variant";
import styles from "./TimelineCard.module.css";
import { useNavigate } from "react-router-dom";
import { useCallback } from "react";


const TimelineCard = () => {
  const navigate = useNavigate();

  const onButtonClick = useCallback(() => {
    navigate("https://www.canva.com/design/DAF1pwABv-4/j9D05rP_r4MSkixC19FJLg/view?utm_content=DAF1pwABv-4&utm_campaign=designshare&utm_medium=link&utm_source=viewer");
  }, [navigate]);
  return (
    <div className={styles.timeline}>
      <div className={styles.contentTimelineDesktop}>
        <h2 className={styles.vuoiSaperneDi}>
          Vuoi saperne di più? Ecco a te un breve riassunto delle mie esperienze
        </h2>
        <div className={styles.desktop}>
          <div className={styles.containerImg}>
          <img
            className={styles.desktopChild}
            alt="giovane lorenzo goretti mentre suona come dj nel 2016"
            src="/img-timeline-2016@2x.png"
          />
          </div>
          <p className={styles.avevoPocoPiContainer}>
            <span className={styles.span}>
              <b>2016</b>
            </span>
            <span className={styles.avevoPocoPiDi14AnniQuan}>
              <span>
                Avevo poco più di 14 anni quando chiesi ai miei genitori per
                natale la mia prima console. 
                Avevo sempre considerato la musica elettronica e il mondo
                dell’intrattenimento come un qualcosa di affascinante.
                Iniziarono i primi eventi qua e là e pian piano la mia figura
                come dj nasceva.
              </span>
            </span>
          </p>
        </div>
        <div className={styles.oggiDesktop}>
          <p className={styles.avevoPocoPiContainer}>
            <span className={styles.span}>
              <b>2017</b>
            </span>
            <span className={styles.avevoPocoPiDi14AnniQuan}>
              <span>
                Dopo essermi fatto le ossa con le prime feste private
                e compleanni di amici, iniziano ad arrivare le prime
                soddisfazioni. Primi eventi in discoteche e locali e nel maggio
                2017 vinco il contest di Holi Trip, festival a Merate, che mi
                porta ad esibirmi davanti a migliaia di persone
              </span>
            </span>
          </p>
          <div className={styles.containerImg}>
          <img
            className={styles.desktopChild}
            alt="lorenzo goretti mentre suona come dj nel 2017"
            src="/img-timeline-2017@2x.png"
          />
          </div>
        </div>
        <div className={styles.desktop2}>
          <div className={styles.containerImg}>
          <img
            className={styles.desktopChild}
            alt="lorenzo goretti mentre suona come dj nel 2020"
            src="/img-timeline-2019@2x.png"
          />
          </div>
          <p className={styles.inizioAGirareContainer}>
            <span className={styles.span}>
              <b>2020</b>
            </span>
            <span className={styles.avevoPocoPiDi14AnniQuan}>
              <span>
                Inizio a girare sempre di più ottenendo residenze con format e
                stringendo collaborazioni con agenzie di eventi, che mi aprono
                un nuovo portone, quello dei matrimoni e degli eventi privati
                come cene aziendali, 18esimi e 50esimi.
              </span>
            </span>
          </p>
          <div className={styles.ellipseDiv} />
        </div>
        <div className={styles.oggiDesktop} itemScope itemType="https://schema.org/Organization">
          <p className={styles.oggiAttualmenteCollaboroContainer}>
            <span className={styles.span}>
              <b>Oggi</b>
            </span>
            <span className={styles.avevoPocoPiDi14AnniQuan}>
              <span>
                Attualmente collaboro con diversi format della brianza e locali
                sia brianzoli che milanesi. Inoltre sono anche collaboratore di
                una agenzia per eventi privati. Questi sono alcuni dei locali e
                location dove mi sono esibito nel corso della mia esperienza:
                <span itemProp="worksFor">Club Modà, Moregallo disco club, Inside Club, Papaya, Lime, The
                Beach Club, Polaris, Villa Casaforte di Bisone, Villa Sofia,
                Forest, Villa Fontana, Bischeri.</span>
              </span>
            </span>
          </p>
          <div className={styles.containerImg}>
          <img
            className={styles.desktopChild}
            alt="lorenzo goretti mentre suona come dj oggi"
            src="/img-timeline-oggi@2x.png"
          />
          </div>
        </div>
        <div className={styles.ctaFinale}>
           <a className={styles.ctaCanvas} href="https://www.canva.com/design/DAF1pwABv-4/j9D05rP_r4MSkixC19FJLg/view?utm_content=DAF1pwABv-4&utm_campaign=designshare&utm_medium=link&utm_source=viewer" target="blank">
            Scarica la mia scheda di presentazione
           </a>
        </div>
      </div>
    </div>
  );
};

export default TimelineCard;
