import styles from "./CollaboratingCompaniesCard.module.css";
import { useState } from 'react';
import Carousel from "./carouselCompanies";

  function ControlledCarousel() {
    const [index, setIndex] = useState(0);
    const handleSelect = (selectedIndex) => {
      setIndex(selectedIndex);
    };
  return (
    <div className={styles.aziendeChiSono}>
      <h2 className={styles.aziendeConCui}>{`Aziende con cui collaboro: `}</h2>
      <div className={styles.partners}>
        <div className={styles.leFollie}>
        <img className={styles.leFollie1} src="/logo-le-follie.png" alt="logo le follie"></img>
        </div>
        <div className={styles.leFollie2}>
          <img className={styles.leFollie1} src="/logo-moda.png" alt="logo club modà"></img>
        </div>
        <div className={styles.leFollie2}>
        <img className={styles.leFollie1} src="/logo-just-people.png" alt="logo just people"></img>
        </div>
        <div className={styles.leFollie6}>
        <img className={styles.leFollie1} src="/logo-moregallo.png" alt="logo moregallo"></img>
        </div>
        <div className={styles.azienda5}>
        <img className={styles.leFollie1} src="/logo-papaya.png"alt="logo papaya"></img>
        </div>
      </div>
      <Carousel></Carousel>
      </div>
  )
};

export default ControlledCarousel;
