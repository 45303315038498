import { useMemo } from "react";
import styles from "./ReviewCardForm.module.css";

const ReviewCardForm = ({ propPadding }) => {
  const reviewsContentStyle = useMemo(() => {
    return {
      padding: propPadding,
    };
  }, [propPadding]);

  return (
    <div className={styles.reviews}>
      <div className={styles.reviewsContent} style={reviewsContentStyle}>
        <h2 className={styles.parolaAVoi}>Parola a voi!</h2>
        <div className={styles.div}>
          <div className={styles.containerImg}>
          <img className={styles.child} alt="giovane coppia di sposi" src="/carly-rae-hobbins-zNHOIzjJiyA-unsplash.jpg" />
          </div>
          <div className={styles.marcoEGiuliaParent}>
            <div className={styles.marcoEGiulia}>Marco e Giulia</div>
            <div className={styles.marcoEGiulia}>Matrimonio</div>
            <div className={styles.startReviews}>
              <img
                className={styles.starSolid1Icon}
                alt=""
                src="/starsolid-1.svg"
              />
              <img
                className={styles.starSolid2Icon}
                alt=""
                src="/starsolid-1.svg"
              />
              <img
                className={styles.starSolid3Icon}
                alt=""
                src="/starsolid-1.svg"
              />
              <img
                className={styles.starSolid4Icon}
                alt=""
                src="/starsolid-1.svg"
              />
              <img
                className={styles.starSolid5Icon}
                alt=""
                src="/starsolid-1.svg"
              />
              <img
                className={styles.starSolid6Icon}
                alt=""
                src="/starsolid-1.svg"
              />
            </div>
            <div className={styles.abbiamoContattatoLorenzoContainer}>
              <p
                className={styles.abbiamoContattatoLorenzo}
              >{`“Abbiamo contattato Lorenzo per il nostro  matrimonio e non possiamo ritenerci più che  soddisfatti. Lorenzo è un vero professionista,  aiutandoci nella gestione del matrimonio e `}</p>
              <p
                className={styles.abbiamoContattatoLorenzo}
              >{`permettendoci di scegliere la musica che desideravamo. `}</p>
              <p className={styles.abbiamoContattatoLorenzo}>
                Totalmente consigliato.”
              </p>
            </div>
          </div>
        </div>
        <div className={styles.div}>
          <div className={styles.containerImg}>
          <img className={styles.child} alt="giovane ragazza di 18 anni" src="/brooke-cagle-6e9NdtnJYxU-unsplash.jpg" />
          </div>
          <div className={styles.marcoEGiuliaParent}>
            <div className={styles.marcoEGiulia}>Eleonora</div>
            <div className={styles.marcoEGiulia}>Festa dei 18 anni</div>
            <div className={styles.startReviews}>
              <img
                className={styles.starSolid1Icon}
                alt=""
                src="/starsolid-1.svg"
              />
              <img
                className={styles.starSolid2Icon}
                alt=""
                src="/starsolid-1.svg"
              />
              <img
                className={styles.starSolid3Icon}
                alt=""
                src="/starsolid-1.svg"
              />
              <img
                className={styles.starSolid4Icon}
                alt=""
                src="/starsolid-1.svg"
              />
              <img
                className={styles.starSolid5Icon}
                alt=""
                src="/starsolid-1.svg"
              />
              <img
                className={styles.starSolid6Icon}
                alt=""
                src="/starsolid-1.svg"
              />
            </div>
            <div className={styles.abbiamoContattatoLorenzoContainer}>
              <p className={styles.abbiamoContattatoLorenzo}>
                “Tramite un’agenzia di eventi situata nelle mie zone, ho
                ingaggiato Lorenzo come dj per la festa dei miei 18anni.
              </p>
              <p className={styles.abbiamoContattatoLorenzo}>
                {" "}
                Che dire la festa è stata spettacolare!
              </p>
              <p className={styles.abbiamoContattatoLorenzo}>
                Lorenzo è riuscito a capire i miei gusti a pieno e mi sono
                divertita molto.
              </p>
              <p className={styles.abbiamoContattatoLorenzo}>
                Ottimo rapporto qualità-prezzo.”
              </p>
            </div>
          </div>
        </div>
        <div className={styles.div}>
          <div className={styles.containerImg}>
          <img className={styles.child} alt="uomo sulla 50ina di anni" src="/alexander-grey-W2hSpLh5caQ-unsplash.jpg"/>
          </div>
          <div className={styles.marcoEGiuliaParent}>
            <div className={styles.marcoEGiulia}>Giuseppe</div>
            <div className={styles.marcoEGiulia}>Festa dei 50 anni</div>
            <div className={styles.startReviews}>
              <img
                className={styles.starSolid1Icon}
                alt=""
                src="/starsolid-1.svg"
              />
              <img
                className={styles.starSolid2Icon}
                alt=""
                src="/starsolid-1.svg"
              />
              <img
                className={styles.starSolid3Icon}
                alt=""
                src="/starsolid-1.svg"
              />
              <img
                className={styles.starSolid4Icon}
                alt=""
                src="/starsolid-1.svg"
              />
              <img
                className={styles.starSolid5Icon}
                alt=""
                src="/starsolid-1.svg"
              />
              <img
                className={styles.starSolid6Icon}
                alt=""
                src="/starsolid-1.svg"
              />
            </div>
            <div className={styles.abbiamoContattatoLorenzoContainer}>
              <p className={styles.abbiamoContattatoLorenzo}>
                “Devo dire che ero un po’ preoccupato quando mi hanno proposto
                un ragazzo di 24 anni per la mia festa dei 50anni, avevo il
                timore che non conoscesse a livello musicale i miei anni.
              </p>
              <p className={styles.abbiamoContattatoLorenzo}>
                Invece sono rimasto stupito dal suo repertorio.
              </p>
              <p className={styles.abbiamoContattatoLorenzo}>
                Mi sono divertito come da ragazzo”
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReviewCardForm;
